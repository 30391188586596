<template>
    <div class="home">
      <main>
        <h1>Fahrzeuge der Freiwilligen Feuerwehr Lunestedt</h1>
      <article class="fahrzeugmain">
        
        <h2>HLF 10
          <br><span class="lg-view">(Hilfeleistungslöschfahrzeug)</span>
              <span class="sm-view">(Hilfeleistungs- <br> löschfahrzeug)</span>
        </h2><p>
       <br></p>
        <div>
          <img class="steck_bild" src="@/components/images/fahrt/HLF.jpg">
          <div class="steck-brief-wide">
            <div class="steck-row">
              <div class="points">Funkrufname:</div>
              <div class="content">Florian Cuxhaven 30-46-20</div>
            </div>
            <div class="steck-row">
              <div class="points">Kennzeichen:</div>
              <div class="content">CUX F 4620</div>
            </div>
            <div class="steck-row">
              <div class="points">Fahrgestell:</div>
              <div class="content">MAN TGM 13.290 4x4 BL, 213 kW/290 PS, 6 Zyl. Diesel</div>
            </div>
            <div class="steck-row">
              <div class="points">Aufbau:</div>
              <div class="content">Rosenbauer AT, Luckenwalde</div>
            </div>
            <div class="steck-row">
              <div class="points">Baujahr:</div>
              <div class="content">12/2020</div>
            </div>
            <div class="steck-row">
              <div class="points">Gesamtgewicht:</div>
              <div class="content">16.000Kg</div>
            </div>
            <div class="steck-row">
              <div class="points">Fahrzeugabmessungen:</div>
              <div class="content">L x B x H: 8.500 x 2.500 x 3.400 mm (inkl. Haspeln)</div>
            </div>
            <div class="steck-row">
              <div class="points">Pumpe:</div>
              <div class="content">Rosenbauer N35</div>
            </div>
            <div class="steck-row">
              <div class="points">Tankinhalt:</div>
              <div class="content">2400 Liter</div>
            </div>
            <div class="steck-row">
              <div class="points">Schaum-Druckzumischsystem:</div>
              <div class="content">Rosenbauer DIGIMATIC mit Schaumitteltank 200 Liter </div>
            </div>
            <div class="steck-row">
              <div class="points">Navigation:</div>
              <div class="content">Selectric Columbus   </div>
            </div>
            <div class="steck-row">
              <div class="points">Ausrüstung:</div>
              <div class="content">Gemäß DIN 14530-26:2019-11 und weitere Zusatzausrüstung</div>
            </div>
          </div>
        </div> 
        
        <br>
        <h2>TLF 8/18W
          <br><span class="lg-view">(Tanklöschfahrzeug Waldbrand)</span>
              <span class="sm-view">(Tanklöschfahrzeug <br> Waldbrand)</span>
        </h2><p>
       <br></p>
        <div>
          <img class="steck_bild" src="@/components/images/fahrt/TLF.jpg">
          <div class="steck-brief-wide">
            <div class="steck-row">
              <div class="points">Funkrufname:</div>
              <div class="content">Florian Cuxhaven 30-20-20</div>
            </div>
            <div class="steck-row">
              <div class="points">Kennzeichen:</div>
              <div class="content">CUX F 3020</div>
            </div>
            <div class="steck-row">
              <div class="points">Fahrgestell:</div>
              <div class="content">MB Unimog U1300L, 124 KW/168 PS, 6 Zyl. Diesel, 5,6L Hubr.</div>
            </div>
            <div class="steck-row">
              <div class="points">Aufbau:</div>
              <div class="content">1984 Ziegler, 2022 Meyer Feuerwehrbedarf, Rehden</div>
            </div>
            <div class="steck-row">
              <div class="points">Baujahr:</div>
              <div class="content">03/1984, Umbau 04/2022 Fa. Meyer und Fa. Mareck</div>
            </div>
            <div class="steck-row">
              <div class="points">Gesamtgewicht:</div>
              <div class="content">8.500 Kg</div>
            </div>
            <div class="steck-row">
              <div class="points">Fahrzeugabmessungen:</div>
              <div class="content">L x B x H: 5.500 x 2.440 x 2.900 mm</div>
            </div>
            <div class="steck-row">
              <div class="points">Pumpe:</div>
              <div class="content">Ziegler 8/8</div>
            </div>
            <div class="steck-row">
              <div class="points">Tankinhalt:</div>
              <div class="content">2000 Liter</div>
            </div>
            <div class="steck-row">
              <div class="points">Navigation:</div>
              <div class="content">Selectric Columbus</div>
            </div>
            <div class="steck-row">
              <div class="points">Ausrüstung:</div>
              <div class="content">Gemäß DIN 14530-18 und weitere Zusatzausrüstung für die Wald- und Vegetationsbrandbekämpfung u.a.</div>
            </div>
            <div class="steck-row">
              <div class="points">Besonderheit:</div>
              <div class="content">Lichtmast mit 4 LED Strahler, Bodensprühdüsen vorne und hinten vor den Achsen. 
                Sie können bei Flächenbränden, Reinigungs- oder Dekontaminierungsarbeiten eingesetzt werden.</div>
            </div>
          </div>
        </div>
  
        <br>
        <h2>MTF
          <br><span class="lg-view">(Mannschaftstransportfahrzeug)</span>
              <span class="sm-view">(Mannschafts- <br> transport- <br> fahrzeug)</span>
        </h2><p>
       <br></p>
        <div>
          <img class="steck_bild" src="@/components/images/fahrt/MTW.jpg">
          <div class="steck-brief-wide">
            <div class="steck-row">
              <div class="points">Funkrufname:</div>
              <div class="content">Florian Cuxhaven 30-17-20</div>
            </div>
            <div class="steck-row">
              <div class="points">Kennzeichen:</div>
              <div class="content">CUX F 1720</div>
            </div>
            <div class="steck-row">
              <div class="points">Fahrgestell:</div>
              <div class="content">Mercedes Vito 116, 120 kW/163 PS, 4 Zyl. Diesel, Euro 5</div>
            </div>
            <div class="steck-row">
              <div class="points">Aufbau:</div>
              <div class="content">Mercedes Benz, FF-Lunestedt</div>
            </div>
            <div class="steck-row">
              <div class="points">Baujahr:</div>
              <div class="content">11/2013</div>
            </div>
            <div class="steck-row">
              <div class="points">Gesamtgewicht:</div>
              <div class="content">3.050 Kg</div>
            </div>
            <div class="steck-row">
              <div class="points">Fahrzeugabmessungen:</div>
              <div class="content">L x B x H: 5.238 x 1.906 x 2010 mm </div>
            </div>
            <div class="steck-row">
              <div class="points">Sitzplätze:</div>
              <div class="content">8</div>
            </div>
            <div class="steck-row">
              <div class="points">Navigation:</div>
              <div class="content">Selectric Columbus</div>
            </div>
            <div class="steck-row">
              <div class="points">Ausrüstung:</div>
              <div class="content">Zusatzausrüstung für Absicherungsmaßnahmen u.a.</div>
            </div>
            <div class="steck-row">
              <div class="points">Besonderheit:</div>
              <div class="content">Anhängerkupplung, gebremst 2000kg, Ausführung Extra-lang</div>
            </div>
          </div>
        </div> 
        
        <br>
        <h2>Anhänger Logistik 1
        </h2><p>
       <br></p>
        <div>
          <img class="steck_bild" src="@/components/images/fahrt/Bild1.jpg">
          <div class="steck-brief-wide">
            <div class="steck-row">
              <div class="points">Funkrufname:</div>
              <div class="content">Florian Cuxhaven 30-69-20</div>
            </div>
            <div class="steck-row">
              <div class="points">Kennzeichen:</div>
              <div class="content">CUX F 6920</div>
            </div>
            <div class="steck-row">
              <div class="points">Fahrgestell:</div>
              <div class="content">Böckmann</div>
            </div>
            <div class="steck-row">
              <div class="points">Ausbau:</div>
              <div class="content">Freiwillige Feuerwehr Lunestedt</div>
            </div>
            <div class="steck-row">
              <div class="points">Baujahr:</div>
              <div class="content">2022</div>
            </div>
            <div class="steck-row">
              <div class="points">Gesamtgewicht:</div>
              <div class="content">750 Kg</div>
            </div>
            <div class="steck-row">
              <div class="points">Bemerkung:</div>
              <div class="content">Logistikanhänger für alle Fachabteilungen der Ortswehr</div>
            </div>
          </div>
        </div> 

        <br>
        <h2>Anhänger Logistik 2
        </h2><p>
       <br></p>
        <div>
          <!--<img class="steck_bild" src="@/components/images/fahrt/MTW.jpg"> -->
          <div class="steck-brief-wide">
            <div class="steck-row">
              <div class="points">Funkrufname:</div>
              <div class="content">Florian Cuxhaven 30-69-20-Z</div>
            </div>
            <div class="steck-row">
              <div class="points">Kennzeichen:</div>
              <div class="content">CUX V 635</div>
            </div>
            <div class="steck-row">
              <div class="points">Fahrgestell:</div>
              <div class="content">DUIS</div>
            </div>
            <div class="steck-row">
              <div class="points">Ausbau:</div>
              <div class="content">Freiwillige Feuerwehr Lunestedt</div>
            </div>
            <div class="steck-row">
              <div class="points">Baujahr:</div>
              <div class="content">1972</div>
            </div>
            <div class="steck-row">
              <div class="points">Gesamtgewicht:</div>
              <div class="content">1.900 Kg</div>
            </div>
            <div class="steck-row">
              <div class="points">Bemerkung:</div>
              <div class="content">Transportanhänger für Zelte, Fußboden und Gestänge</div>
            </div>
          </div>
        </div>
      
        <br>
        <h2>Feuerwehrspritze/ Handdruckspritze
        </h2><p>
       <br></p>
        <div>
          <img class="steck_bild" src="@/components/images/fahrt/Bild2.jpg">
          <div class="steck-brief-wide">
            <div class="steck-row">
              <div class="points">Rufname:</div>
              <div class="content">Alte Spritze (Saug-Druckspritze)</div>
            </div>
            <div class="steck-row">
              <div class="points">Antrieb:</div>
              <div class="content">Pferd/e oder Landwirtschaftliche Zugmaschine</div>
            </div>
            <div class="steck-row">
              <div class="points">Hersteller:</div>
              <div class="content">A. Rönneburg, Spritzenfabrik, Uelzen</div>
            </div>
            <div class="steck-row">
              <div class="points">Ausbau:</div>
              <div class="content">Freiwillige Feuerwehr Lunestedt</div>
            </div>
            <div class="steck-row">
              <div class="points">Baujahr:</div>
              <div class="content">1902</div>
            </div>
            <div class="steck-row">
              <div class="points">Restauriert:</div>
              <div class="content">2020</div>
            </div>
            <div class="steck-row">
              <div class="points">Gesamtgewicht:</div>
              <div class="content">880 Kg</div>
            </div>
            <div class="steck-row">
              <div class="points">Fahrzeugabmessungen:</div>
              <div class="content">L x B x H: 4500 x 1.906 x 2010 mm </div>
            </div>
            <div class="steck-row">
              <div class="points">Pumpe:</div>
              <div class="content">Pumpenleistung um die 260 Liter bei 60 Doppelhüben. Max. Reichweite bis zu 31 m.</div>
            </div>
            <div class="steck-row">
              <div class="points">Bemerkung:</div>
              <div class="content">Ehemaliger Standort: Freiwillige Feuerwehr Freschluneberg. Voll Funktionsfähig im Ruhestand.</div>
            </div>
          </div>
        </div>

      </article>
  </main>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'Fahrzeuge',
    data() {
      return {
        isVisible: false,
        isVisible1: false,
        isVisible2: false,
        isVisible3: false,

      }
    },
    components: {
    },
    methodes: {
    }
  }
  </script>
  <style scoped>
  .fahrzeugmain {
    margin-top: 20px;
  }
  .steck_bild {
    width: 50%;
    height: auto;
    margin-left: 25%;
    margin-right: 25%;
  }
  .lg-view {
    display: inline-block;
  }
  .sm-view {
    display: none;
  }
  .bilder {
    width: 20%;
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-top: 15px;
  }
  .steck-brief-wide {
    margin-top: 30px;
    margin-left: 20%;
    width:60%;
    margin-right: 20%;
    display: flex;
    flex-wrap: wrap;
  }
  .steck-row {
    width : 100%;
    display: flex;
  }
  .points {
    font-weight: bold;
    font-size: 25px;
    width: 50%;
    padding-bottom: 20px;
    overflow-wrap: break-word;
  }
  .content {
    font-size: 25px;
    width: 50%;
    padding-bottom: 20px;
    overflow-wrap: break-word;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  button {
    border-radius: 5px;
    border-color: var(--inverted-color);
    background-color: transparent;
    font-size: 20px;
    color: var(--inverted-color);
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: 0.3s;
  }
  button:hover {
    color: var(--complementary-color);
    transition: 0.3s;
  }
  h2 {
    color: var(--primary-color);
    margin-top: 50px;
    margin-left: 20%;
    margin-right: 20;
  }
  h3 {
    margin-top: 50px;
  }
@media (max-width: 991.5px) {
  h2 {
    margin-left: 5%;
  }
  .steck_bild {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .lg-view {
    display: none;
  }
  .sm-view {
    display: inline-block;
  }
  .steck-brief-wide {
    margin-top: 30px;
    margin-left: 5%;
    width: 90%;
    margin-right: 5%;
    display: flex;
    flex-wrap: wrap;
  }
  .steck-row {
    width : 100%;
    display: block;
  }
  .points {
    font-weight: bold;
    font-size: 20px;
    width: 100%;
    padding-bottom: 20px;
  }
  .content {
    font-size: 20px;
    width: 100%;
    padding-bottom: 20px;
  }
}
  </style>
  